<template>
  <!-- Overview Modal -->
  <div>
    <svg
      v-on:click="open"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
    </svg>

    <b-modal :id="'overview-' + party.id">
      <template slot="modal-header" class="mb-0 w-100">
        <h4 class="font-weight-bold pb-0 mb-0">Table {{ party.table }}</h4>
        <button class="btn ml-auto primary-blue" v-if="party.status === 1">
          <span class="font-weight-bold" v-on:click="closeOrder"
            >Close Tab</span
          >
          <b-spinner small v-if="this.loading" type="grow"></b-spinner>
        </button>
      </template>

      <p class="font-weight-bold mb-0">
        Last Name: <span class="font-weight-normal">{{ party.last_name }}</span>
      </p>
      <p class="font-weight-bold mb-0">
        Order Status:
        <span class="font-weight-normal">{{ party.get_status_display }}</span>
      </p>
      <p class="font-weight-bold mb-0">
        Arrival Time:
        <span class="font-weight-normal">{{
          party.createdAt | moment("dddd, h:mm a")
        }}</span>
      </p>
      <br />
      <p class="font-weight-bold mb-0">
        Active Tickets:
        <span class="font-weight-normal" v-if="hideItems">None</span>
      </p>
      <Ticket
        v-for="ticket in party.tickets"
        :key="'ticket-' + ticket.id"
        :ticket="ticket"
      >
      </Ticket>
      <template slot="modal-footer">
        <button class="btn" v-on:click="close">
          <span class="font-weight-bold">Close</span>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Ticket from "../components/Ticket.vue";

export default {
  name: "OverviewModal",
  props: ["party"],
  data: function() {
    return {
      loading: false,
    };
  },
  components: {
    Ticket,
  },
  methods: {
    open: function() {
      this.$forceUpdate();
      this.$bvModal.show("overview-" + this.party.id);
    },
    close: function() {
      this.$bvModal.hide("overview-" + this.party.id);
    },
    closeOrder: function() {
      this.$store
        .dispatch("closeOrder", { order_id: this.party.id })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.close();
        });
    },
  },
  computed: {
    orderItems: function() {
      return this._.orderBy(
        this._.flatMap(this.party.tickets, function(obj) {
          return obj.items;
        }),
        "-status"
      );
    },
    hideItems: function() {
      return this._.isEmpty(this.orderItems);
    },
  },
};
</script>
<style scoped>
.badge {
  vertical-align: text-top !important;
}
</style>
