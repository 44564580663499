<template>
  <!-- Party Table -->
  <b-row bo-gutters>
    <b-col class="mt-0">
      <table class="table btn-table text-center">
        <thead>
          <tr>
            <th scope="col">Table</th>
            <th scope="col">Overview</th>
            <th scope="col">QR Code</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <!-- Check in Modal -->
          <tr>
            <td colspan="12" class="pt-0 mt-0">
              <AddPartyModal></AddPartyModal>
            </td>
          </tr>
          <tr v-for="party in filteredData" v-bind:key="'party-' + party.id">
            <td>
              {{ party.table }}
            </td>
            <td>
              <OverviewModal :party="party"></OverviewModal>
            </td>
            <td>
              <QRModal :party="party"></QRModal>
            </td>
            <td>
              <ActionsModal :party="party"></ActionsModal>
            </td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import AddPartyModal from "../components/AddPartyModal.vue";
import QRModal from "../components/QRModal.vue";
import OverviewModal from "../components/OverviewModal.vue";
import ActionsModal from "../components/ActionsModal.vue";

export default {
  name: "TicketCard",
  props: ["parties"],
  components: {
    AddPartyModal,
    QRModal,
    OverviewModal,
    ActionsModal,
  },
  computed: {
    filteredData: function() {
      return this._.sortBy(this.parties, "table");
    },
  },
};
</script>
<style scoped>
.card {
  width: 97.5%;
  border-color: none !important;
}
th,
tr,
thead,
td {
  border-top: none !important;
  border-bottom: none !important;
}
@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
}
</style>
