<template>
  <!-- QR Modal -->
  <div>
    <button
      class="btn btn-sm font-weight-bold primary-blue"
      v-on:click="$bvModal.show('qr-' + party.id)"
    >
      View
    </button>

    <b-modal :id="'qr-' + party.id">
      <template slot="modal-title" class="mb-0">
        <h4 class="font-weight-bold">
          Scan Code - {{ party.last_name }} {{ party.table }}
        </h4>
      </template>

      <VueQrcode :value="party.url" class="w-100" />

      <template slot="modal-footer">
        <button class="btn primary-blue" v-on:click="close">
          <span class="font-weight-bold">Close</span>
        </button>
        <a class="btn font-weight-bold" target="_blank" :href="party.url"
          >Manual Serve</a
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";

export default {
  name: "QRModal",
  props: ["party"],
  components: {
    VueQrcode,
  },
  methods: {
    close: function() {
      this.$bvModal.hide("qr-" + this.party.id);
    },
  },
};
</script>
<style scoped>
.badge {
  vertical-align: top;
}
</style>
