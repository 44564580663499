<template>
  <!-- Runner -->
  <b-container fluid>
    <Nav></Nav>
    <PartyTable :parties="$store.state.parties"></PartyTable>
  </b-container>
</template>

<script>
import Nav from "../components/Nav.vue";

import PartyTable from "../components/PartyTable.vue";

export default {
  name: "Runner",
  components: {
    Nav,
    PartyTable,
  },
  mounted: function() {
    //this.$store.dispatch('connect');
    this.$store.dispatch("getParties");
  },
};
</script>
