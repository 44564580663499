<template>
  <!-- Actions Modal -->
  <div>
    <button
      class="btn btn-sm font-weight-bold primary-blue"
      v-on:click="$bvModal.show('delivery-' + party.id)"
      v-if="!hideDeliver"
    >
      Deliver Items
    </button>
    <button
      class="btn btn-sm font-weight-bold primary-blue"
      v-on:click="$bvModal.show('payment-' + party.id)"
      v-if="!hidePayment"
    >
      Take Payment
    </button>

    <b-modal :id="'delivery-' + party.id">
      <template slot="modal-header" class="mb-0 w-100">
        <h4 class="font-weight-bold pb-0 mb-0">
          Delivery for Table {{ party.table }}
        </h4>
      </template>
      <OrderItem
        v-for="item in orderItems"
        :item="item"
        :payment_status="false"
        :countdown_timer="false"
        :key="'orderItem' + item.id"
      ></OrderItem>
      <template slot="modal-footer">
        <button class="btn primary-blue" v-on:click="close('delivery-')">
          <span class="font-weight-bold">Close</span>
        </button>
        <button class="btn">
          <span class="font-weight-bold" v-on:click="confirmDelivery">
            Confirm Delivery
          </span>
          <b-spinner
            small
            v-if="this.loading"
            type="grow"
            style="vertical-align:middle;"
          ></b-spinner>
        </button>
      </template>
    </b-modal>

    <b-modal :id="'payment-' + party.id">
      <template slot="modal-header" class="mb-0 w-100">
        <h4 class="font-weight-bold pb-0 mb-0">
          Take Payment for Table {{ party.table }}
        </h4>
      </template>
      <template slot="modal-footer">
        <button class="btn primary-blue" v-on:click="close('payment-')">
          <span class="font-weight-bold">Close</span>
        </button>
        <button class="btn">
          <span class="font-weight-bold">
            Confirm Payment
          </span>
          <b-spinner
            small
            v-if="this.loading"
            type="grow"
            style="vertical-align:middle;"
          ></b-spinner>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import OrderItem from "../components/OrderItem.vue";

export default {
  name: "ActionsModal",
  props: ["party"],
  data: function() {
    return {
      loading: false,
    };
  },
  components: {
    OrderItem,
  },
  methods: {
    close: function(tag) {
      this.$bvModal.hide(tag + this.party.id);
    },
    confirmDelivery: function(ticket_id) {
      this.$store
        .dispatch("confirmDelivery", { ticket_id: ticket_id })
        .then((response) => {
          console.log(response);
        });
    },
  },
  computed: {
    orderItems: function() {
      return this._.flatMap(this.party.tickets, function(obj) {
        return obj.items;
      });
    },
    itemsToDeliver: function() {
      return this._.pickBy(this.orderItems, function(item) {
        return item.status == 3;
      });
    },
    formatItems: function() {
      return this._.groupBy(this.itemsToDeliver, function(item) {
        return item.category_name;
      });
    },
    hideDeliver: function() {
      return this._.isEmpty(this.itemsToDeliver);
    },
    hidePayment: function() {
      return true;
    },
  },
};
</script>
<style scoped>
.badge {
  vertical-align: text-top !important;
}
</style>
