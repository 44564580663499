<template>
  <!-- Add Order Modal -->
  <div>
    <button
      class="btn btn-sm font-weight-bold mt-0 primary-blue shadow"
      style="width:100%;"
      v-b-modal.add-party
    >
      Add Party +
      <template v-if="this.loading">
        <b-spinner type="grow"></b-spinner>
      </template>
    </button>

    <b-modal id="add-party">
      <template slot="modal-title" class="mb-0">
        <h4 class="font-weight-bold">
          Add Party
        </h4>
      </template>
      <b-form @submit="submitParty">
        <label for="text-last_name" class="mt-3">Last Name*</label>
        <b-form-input
          id="text-last_name"
          v-model="last_name"
          placeholder=""
        ></b-form-input>
        <label for="text-password" class="mt-3">Table*</label>
        <b-form-input
          type="number"
          id="text-table"
          v-model="table"
        ></b-form-input>
        <p class=" mt-3 small" id="error">{{ error }}</p>
      </b-form>
      <template slot="modal-footer">
        <button class="btn primary-blue" v-on:click="close">
          <span class="font-weight-bold">Close</span>
        </button>
        <button class="btn">
          <span
            class="font-weight-bold"
            v-on:click="submitParty"
            v-if="!this.loading"
          >
            Submit
          </span>
          <b-spinner
            small
            v-if="this.loading"
            type="grow"
            style="vertical-align:middle;"
          ></b-spinner>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ItemModal",
  props: ["item"],
  data: function() {
    return {
      last_name: "",
      table: "",
      error: "",
      loading: false,
    };
  },
  methods: {
    submitParty: function(e) {
      e.preventDefault();
      this.loading = true;

      this.$store
        .dispatch("addParty", {
          last_name: this.last_name,
          table: parseInt(this.table),
        })
        .then((response) => {
          //this.$store.commit('updateTokens', {'refresh':response.data.refresh, 'access':response.data.access});
          console.log(response.data);
          this.close();
          this.loading = false;
          this.error = "";
          this.last_name = "";
          this.table = "";
        })
        .catch((error) => {
          this.error = error.response.data.Failure + ". Please try again.";
          this.loading = false;
        });
    },
    close: function() {
      this.$bvModal.hide("add-party");
    },
  },
};
</script>
<style scoped>
.badge {
  vertical-align: top;
}
.modal-body {
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
</style>
