<template>
  <div class="card shadow-sm p-3 mt-2">
    <p class="font-weight-bold">Ticket # {{ ticket.id }}</p>
    <OrderItem
      v-for="item in ticket.items"
      :item="item"
      :paymentStatus="true"
      :countdownTimer="true"
      :countdownStart="ticket.countdownStart"
      :dessertsTicker="dessertsTicker"
      :mainsTicker="mainsTicker"
      :appsTicker="appsTicker"
      :key="'orderItem-' + item.id"
    ></OrderItem>
  </div>
</template>

<script>
import OrderItem from "../components/OrderItem.vue";

export default {
  name: "Ticket",
  props: ["ticket"],
  components: {
    OrderItem,
  },
  computed: {
    ticketTime: function() {
      return this._.maxBy(this.ticket.items, "item_cook_time");
    },
    appsMaxTime: function() {
      var item = this._.maxBy(
        this._.filter(this.ticket.items, function(item) {
          return item.category_type === 3;
        }),
        "item_cook_time"
      );
      return item ? item.item_cook_time : 0;
    },
    appsTicker: function() {
      var start = new Date(this.ticket.countdownStart);
      var now = Date.now();
      var timeSince = Math.round((now - start) / 1000);
      return this.appsMaxTime - timeSince;
    },
    mainsMaxTime: function() {
      var item = this._.maxBy(
        this._.filter(this.ticket.items, function(item) {
          return item.category_type === 4;
        }),
        "item_cook_time"
      );
      return item ? item.item_cook_time : 0;
    },
    mainsTicker: function() {
      var timeBetween = 1200; //Time between main and apps specified by the restaurant
      return this.mainsMaxTime > 0
        ? this.appsTicker + (timeBetween - this.mainsMaxTime)
        : 0;
    },
    dessertsMaxTime: function() {
      var item = this._.maxBy(
        this._.filter(this.ticket.items, function(item) {
          return item.category_type === 5;
        }),
        "item_cook_time"
      );
      return item ? item.item_cook_time : 0;
    },
    dessertsTicker: function() {
      var timeBetween = 600; //Time between main and desserts specified by the restaurant
      return this.dessertsMaxTime > 0
        ? this.mainsTicker + (timeBetween - this.dessertsMaxTime)
        : 0;
    },
    calcTime: function() {
      var start = new Date(this.countdownStart);
      var now = Date.now();
      var timeBetween = Math.floor((now - start.getTime()) / 1000);
      return timeBetween;
    },
  },
};
</script>
<style scoped>
.card {
  border-color: none !important;
}
li {
  background-color: white !important;
  color: black;
  border-color: white !important;
}
</style>
